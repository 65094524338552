.weddings-gallery {
  margin-top: 10%;
  background-color: #B49F63;
  color: white;
  /* Ensuring text color is white for contrast */
  text-align: center;
  padding: 0.1%;
}

/* Making images and videos responsive */
.imagegal {
  max-width: 100%;
  /* Ensure maximum width of 100% of its parent */
  height: 600px;
  /* Maintain aspect ratio */
  display: block;
  /* Display block to remove any white space below */
  width: 350px;
}

.row-gal {
  padding-left: 30px;
  padding-right: 30px;
  display: block;
}

/* Responsive adjustments for tablets */
@media (max-width: 1024px) {
  .col-4 {
    width: 50%;
    /* On tablet screens, use 50% width to show two items per row */
    padding: 10px;
    /* Add padding for spacing around the items */
  }

  .row-gal {
    padding-left: 50px;
  }
}

/* Responsive adjustments for mobiles */
@media (max-width: 768px) {
  .col-4 {
    width: 100%;
    /* On small screens, take full width */
    padding: 0;
    /* Remove padding if necessary */
  }

  .row-gal {
    padding: 20px;
  }
}