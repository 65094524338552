/* src/components/Home.css */

.home video {
    width: 100%; /* Default to 80% for larger screens */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Ensures that margin: auto is effective */
    margin: auto; /* Centers the video horizontally */
    margin-top: 1%;
 
    box-shadow: 0 0 8px 2px #AD9551;
  }
  .event-heading {
    display: flex;
    flex-direction: column;
   
    align-items: center;
    text-align: center; /* Centers the heading and tagline */
   
    
    background-color: #AD9551;
    text-transform: uppercase;
    margin-top: 1%;
   
  }
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  .event-heading h2 {
    font-size: 3rem; /* Adjust the font size of the heading */
    color: #ffffff; /* Dark color for the heading text */
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    margin: 1rem;
    
  }
  
 
  .card-section {
    display: flex;
    justify-content: space-around;
    margin: 2vw 0;
  
  }
  
  .card {
    width: 25%;
    min-height: 30vh;
    padding: 2vw;
    border-radius: 0.5vw;
    box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Ensures the pseudo-element doesn't exceed the card's boundaries */
    position: relative; /* Needed for absolute positioning of the pseudo-element */
  }
  
  .card1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('WeddingThum.webp'); /* Your image path */
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease; /* Smooth transition for the transform effect */
    z-index: -1; /* Ensures the image stays behind the card content */
    opacity: 0.6;
   
  }
  
  .card1:hover::before {
    transform: scale(1.1); /* Enlarges the background image */
    opacity: 0.8;
  }
  .card2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('birthdaythum.webp'); /* Your image path */
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease; /* Smooth transition for the transform effect */
    z-index: -1; /* Ensures the image stays behind the card content */
    opacity: 0.6;
   
  }
  
  .card2:hover::before {
    transform: scale(1.1); /* Enlarges the background image */
    opacity: 0.8;
  }
  .card3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('Babythum.webp'); /* Your image path */
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease; /* Smooth transition for the transform effect */
    z-index: -1; /* Ensures the image stays behind the card content */
    opacity: 0.6;
   
  }
  
  .card3:hover::before {
    transform: scale(1.1); /* Enlarges the background image */
    opacity: 0.8;
  }
  .card4::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('collegefest.webp'); /* Your image path */
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease; /* Smooth transition for the transform effect */
    z-index: -1; /* Ensures the image stays behind the card content */
    opacity: 0.6;
   
  }
  
  .card4:hover::before {
    transform: scale(1.1); /* Enlarges the background image */
    opacity: 0.8;
  }
  .card5::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('corporate.webp'); /* Your image path */
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease; /* Smooth transition for the transform effect */
    z-index: -1; /* Ensures the image stays behind the card content */
    opacity: 0.6;
   
  }
  
  .card5:hover::before {
    transform: scale(1.1); /* Enlarges the background image */
    opacity: 0.8;
  }
  .card6::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('school.webp'); /* Your image path */
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease; /* Smooth transition for the transform effect */
    z-index: -1; /* Ensures the image stays behind the card content */
    opacity: 0.6;
   
  }
  
  .card6:hover::before {
    transform: scale(1.1); /* Enlarges the background image */
    opacity: 0.8;
  }
  .card1 h3 {
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
    display: inline; /* Adjusts the display to wrap the content */
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
    text-transform: uppercase;
    
  }
  .card1 p{
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
   font-weight: 700;
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
  }
  .card2 h3 {
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
    display: inline; /* Adjusts the display to wrap the content */
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
    text-transform: uppercase;
  }
  .card2 p{
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
   font-weight: 700;
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
  }
  .card3 h3 {
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
    display: inline; /* Adjusts the display to wrap the content */
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
    text-transform: uppercase;
  }
  .card3 p{
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
   font-weight: 700;
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
  }
  .card4 h3 {
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
    display: inline; /* Adjusts the display to wrap the content */
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
    text-transform: uppercase;
  }
  .card4 p{
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
   font-weight: 700;
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
  }
  .card5 h3 {
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
    display: inline; /* Adjusts the display to wrap the content */
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
    text-transform: uppercase;
  }
  .card5 p{
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
   font-weight: 700;
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
  }
  .card6 h3 {
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
    display: inline; /* Adjusts the display to wrap the content */
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
    text-transform: uppercase;
  }
  .card6 p{
    color: #000000; /* Sets the text color to white */
    background-color: rgba(255, 255, 255, 0.2); /* Optional: Adds a slight white highlight background */
   font-weight: 700;
    padding: 0.5em 1em; /* Provides padding around the text */
    border-radius: 0.25em; /* Optional: Rounds the corners of the highlight background */
  }
  .card2, .card3 {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card-section a {
    text-decoration: none; /* Removes underline from links */
    color: inherit; /* Inherits text color from parent elements */
  }
  
  .card-section a.card {
    display: block; /* Ensures the anchor tag behaves like a block element, filling the card */
  }
 

  @keyframes slideInFromRight {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
    
  }
  
  .event-heading h2.animate {
    animation: slideInFromRight 1s ease-out forwards;
  }
  .client-home {
    text-align: center; /* Center align the content for consistency */
    padding: 0rem; /* Provide some padding around the section */
   
    margin-top: 2rem; /* Space from the previous section */
  }
  
  .client-home h2 {
    color: #333; /* Dark color for the heading for better readability */
    font-size: 2rem; /* A larger font size to make the heading stand out */
    margin-bottom: 1rem; /* Space below the heading */
    background-color: #AD9551;
    
  }
  
  .onlyvideos {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    overflow: hidden; 
  
    margin: 0 auto; 
    
    border-radius: 8px; 
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); 
  }
  
  .onlyvideos video {
    width: 25%; /* Make the video fill the container */
    height: auto; /* Ensure the video's height is automatic to maintain aspect ratio */
    border-radius: 3%;
    transition: box-shadow 0.3s ease-in-out; 
    border: 0.2rem solid transparent;
  }
  .onlyvideos video:hover {
    box-shadow: 0 8px 15px rgba(0,0,0,0.3); /* Increase shadow depth on hover */
    border: 0.2rem solid #AD9551; 
  }
  .home-footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
   
  }
  
  .AboutUs h3 {
    margin-top: 0;
    color: #FFD700; /* Golden color for the heading */
  }
  .AboutUs p{
    text-align: justify;
    padding: 0.6rem;
  }
  .AboutUs{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .footer {
    text-align: center;
    padding: 1rem 0;
    margin-top: 2rem;
    background-color: #f0f0f0; /* Light grey background, adjust as needed */
    color: #333; /* Dark grey text, adjust as needed */
  }
  
  .footer p {
    margin: 0;
    font-size: 1rem; /* Adjust the font size as needed */
  }
  .home-form-query {
    max-width: 40rem;
    margin: 2rem auto;
    padding: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
  }
  
  .home-form-query .form-heading {
    color: #333; /* Heading text color */
    margin-bottom: 2rem; /* Spacing between the heading and the first input field */
    text-align: center; /* Center the heading */
    font-size: 1.75rem; /* Larger font size for the heading */
  }
  
  .home-form-query div {
    margin-bottom: 1rem;
  }
  
  .home-form-query label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .home-form-query input[type="text"],
  .home-form-query input[type="tel"],
  .home-form-query input[type="email"],
  .home-form-query textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  }
  
  .home-form-query textarea {
    height: 5rem;
    resize: vertical;
  }
  
   /* Media query for mobile devices */
   @media (max-width: 767px) {
    .home video {
      width: 90%; /* Adjust width for smaller screens */
      /* Height remains auto to maintain aspect ratio */
    }
    .card-container {
        justify-content: center; /* Aligns cards centrally on smaller screens */
      }
    
      .card {
        flex-basis: auto; /* Allows cards to expand */
        width: 90%; /* Sets card width on smaller screens */
        margin-bottom: 3%;
      }
      .event-heading h2 {
        font-size: 2rem; /* Adjust the font size of the heading */
        color: #ffffff; /* Dark color for the heading text */
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        margin: 0.5rem;
        
      }
      .card-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        margin: 0.4rem;
      
      }
      .client-home h2 {
        color: #333; /* Dark color for the heading for better readability */
        font-size: 1.3rem; /* A larger font size to make the heading stand out */
        margin-bottom: 1rem; /* Space below the heading */
        background-color: #AD9551;
       padding: 0.7rem;
        
      }
      .onlyvideos {
        display: flex; 
        flex-direction: column;
        justify-content: center; 
        align-items: center; 
        overflow: hidden; 
      
        margin: 0 auto; 
        
        border-radius: 8px; 
        box-shadow: 0 4px 6px rgba(0,0,0,0.1); 
      }
      .AboutUs p {
        max-width: 80%; /* Increase width for better readability on smaller screens */
      }
    
      .AboutUs h3 {
        font-size: 1.25rem; /* Adjust heading size for smaller screens */
      }
    
      .AboutUs {
        padding: 1rem; /* Add padding around the content for better spacing */
      }
      .home-form-query {
        max-width: 95%; /* Allows the form to be slightly narrower than the screen width */
        margin: 1rem auto; /* Reduces the margin for smaller screens */
        padding: 1rem; /* Maintains padding for internal spacing */
      }
    
      .home-form-query .form-heading {
        font-size: 1.5rem; /* Slightly smaller font size to fit smaller screens */
        margin-bottom: 1.5rem; /* Adjusts spacing to be more suitable for mobile */
      }
    
      .home-form-query label {
        margin-bottom: 0.3rem; /* Reduces the space between label and input for a tighter layout */
      }
    
      .home-form-query input[type="text"],
      .home-form-query input[type="tel"],
      .home-form-query input[type="email"],
      .home-form-query textarea {
        padding: 0.4rem; /* Slightly reduces padding to allow more text to be visible */
      }
    
      .home-form-query textarea {
        height: 4rem; /* Adjusts the height to better fit mobile screens */
      }
    
  }
  /* Media query for tablet devices */
@media (min-width: 768px) and (max-width: 1023px) {
  .home video {
    width: 95%; /* Optimize video size for tablet screens */
    padding-top:20% ;
  }
  
  .card {
    width: 40%; /* Adjust card width for tablet screens */
    padding: 0.5vw; /* Adjust padding based on viewport width */
    margin: 0.5vw; /* Ensure cards have enough space between them */
  }
  
  .event-heading h2 {
    font-size: 2.5rem; /* Slightly increase the font size for better readability */
  }
  
  .card-section {
    flex-wrap: wrap; /* Allow card sections to wrap on tablet screens */
    justify-content: space-evenly; /* Evenly distribute space around items */
  }
  
  .client-home h2 {
    font-size: 1.5rem; /* Adjust heading size for better readability on tablets */
  }
  .onlyvideos{
    display: flex;
  
  }
  .onlyvideos video {
    width: 25%; /* Adjust video size to better fit tablet screens */
    padding-top: 0%;
  }
  
  .AboutUs h3 {
    font-size: 1.5rem; /* Increase the font size for headings in the About Us section */
  }

  .AboutUs p {
    max-width: 90%; /* Adjust the max-width for paragraphs to improve readability */
  }
  
  .footer p {
    font-size: 1.1rem; /* Slightly larger font size for footer text */
  }

  .home-form-query {
    max-width: 60rem; /* Increase form width to utilize more space on tablets */
  }
}
form input, form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}
form{
  display: flex;
  flex-direction: column;
  
}
form button {
  padding: 10px 15px;
  background-color:#000000;
  color: #FFD700;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 900;
}
form button:hover{
  color: white;
  background-color: #000000;
  box-shadow: 4px 4px 6px  #000000;
}
