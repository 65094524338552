.College-gallery {
    margin-top: 10%;
     background-color: #B49F63;
     color: #333;
     text-align: center;
     color: white;
     padding: 0.1%;
   }
   .imagegal {
    
    width: auto;
    /* Ensure maximum width of 100% of its parent */
    height: 50vh;
    /* Maintain aspect ratio */
   aspect-ratio: 3/2;
   margin-left: 20%;

    
    /* Display block to remove any white space below */
  }
  
  /* Responsive adjustments for tablets */
@media (max-width: 1024px) {
  .col-4 {
    width: 50%;
    /* On tablet screens, use 50% width to show two items per row */
    padding: 10px;
    /* Add padding for spacing around the items */
  }
}

/* Responsive adjustments for mobiles */
@media (max-width: 768px) {
  .col-4 {
    width: 100%;
    /* On small screens, take full width */
    padding: 0;
    /* Remove padding if necessary */
  }
  .imagegal {
    
    width: auto;
    /* Ensure maximum width of 100% of its parent */
    height: auto;
    /* Maintain aspect ratio */
   aspect-ratio: 3/2;
   margin-left: 0%;

    
    /* Display block to remove any white space below */
  }
}
  